import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import Thumbnail from "../components/thumbnail"
import { graphql } from 'gatsby'

const TeamProfiles = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @media (min-width: 769px) {
    max-width: 784px;
    display: grid;
    grid-template-columns: repeat(3, 151px);
    column-gap: 91px;
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(3, 203px);
    column-gap: 88px;
  }
`

const OurTeam = ({ data }) => (
  <>
    <SEO title="Our Team" />
    <GridItem start="3" end="5">
      <TeamProfiles>
        {data.markdown.edges.map(({ node }) => {
          const { title, jobTitle, thumbnail } = node.frontmatter
          const queryReference = thumbnail.replace(/-|\s/g, '')
          const thumbnailRef = data[queryReference].childImageSharp.fluid
          return <Thumbnail key={node.id} name={title} jobTitle={jobTitle} image={thumbnailRef} url={node.fields.slug} />
        })}
      </TeamProfiles>
    </GridItem>
  </>
)

// Graphql Query fragment
export const fluidImageFragment = graphql`
  fragment fluidImageFragment on File {
    childImageSharp {
      fluid(maxWidth: 170, quality: 100) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`

// Queries for all images on page
export const profilesQuery = graphql`
  query {
    markdown: allMarkdownRemark(sort: {fields: [frontmatter___order]order: ASC}) 
    {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            jobTitle
            thumbnail
          }
        }
      }
    }
    marilynkramerweitzman: file(
      name: { eq: "marilyn-kramer-weitzman" }
    ) {
      ...fluidImageFragment
    }
    keithbrenan: file(
      name: { eq: "keith-brenan" }
    ) {
      ...fluidImageFragment
    }
    catherinetam: file(
      name: { eq: "catherine-tam" }
    ) {
      ...fluidImageFragment
    }
    giovanniwrobel: file(
      name: { eq: "giovanni-wrobel" }
    ) {
      ...fluidImageFragment
    }
    joshualevitt: file(name: { eq: "joshua-levitt" }) {
      ...fluidImageFragment
    }
    keithgottlieb: file(
      name: { eq: "keith-gottlieb" }
    ) {
      ...fluidImageFragment
    }
    mariedaniellefaucher: file(
      name: { eq: "marie-danielle-faucher" }
    ) {
      ...fluidImageFragment
    }
    michaelmerwin: file(
      name: { eq: "michael-merwin" }
    ) {
      ...fluidImageFragment
    }
    peterbazeli: file(
      name: { eq: "peter-bazeli" }
    ) {
      ...fluidImageFragment
    }
    spencercohn: file(
      name: { eq: "spencer-cohn" }
    ) {
      ...fluidImageFragment
    }
    brandonnolasco: file(
      name: { eq: "brandon-nolasco" }
    ) {
      ...fluidImageFragment
    }
    suzannecharles: file(
      name: { eq: "suzanne-charles" }
    ) {
      ...fluidImageFragment
    }
    wenchangqian: file(
      name: { eq: "wenchang-qian" }
    ) {
      ...fluidImageFragment
    }
    yamilarocho: file(
      name: { eq: "yamil-arocho" }
    ) {
      ...fluidImageFragment
    }
  }
`

export default OurTeam
