import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const Container = styled.div`
  margin-bottom: 32px;
  
  a {
    display: block;
  }

  @media(max-width: 1439px) {
    display: ${props => props.profileView ? 'flex' : ''};
    align-items: flex-start;

    & > div {
      margin-right: ${props => props.profileView ? '24px' : ''};
    }
  }

  @media (max-width: 768px) {
    width: calc(50% - 0.6rem);
    
    &:nth-child(odd) {
      margin-right: 1.2rem;
    }

    &.is-profile {
      width: 100%;

      & > div:first-child {
        display: none;
      }

      h3, p {
        font-size: 6.400vw;
        line-height: 1.4em;
        font-weight: 200;
      }
    }
  }
`

const Section = styled.div`
  display: block;
`

const Name = styled.h3`
  font-size: 14px;
  margin: 0;
  font-weight: normal;
  line-height: 1em;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`

const Avatar = styled(Img)`
  width: 137px;
  height: 137px;
  margin-bottom: 26px;

  img {
    filter: ${props => props.filter ? 'grayscale(40%)' : 'grayscale(30%)'};;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media (min-width: 1439px) {
    width: 170px;
    height: 170px;
  }
`

const JobTitle = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: normal;
  line-height: 1em;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`

const Email = styled.a`
  font-size: 14px;
  margin-top: 24px;
  font-weight: normal;
  line-height: 1.2em;

  span {
    display: inline-block;
    border-bottom: 1px solid #FFF;

    @media (max-width: 768px) {
      font-size: 5.333vw;
      font-weight: 400;
      line-height: 1.2em;
    }
  }
`

const Thumbnail = ({ name, jobTitle, image, url, email, profileView = false }) => {
  return (
    <Container profileView={profileView} className={profileView ? `is-profile` : ``}>
      <Section>
        <Link to={url} state={{navIsOpen: false}}>
          <Avatar 
            fluid={image} 
            fadeIn={true} 
            fadeInDuration={600}
            filter={name !== 'Michael Merwin'}
          />
        </Link>
      </Section>
      <Section>
        <Link to={url} state={{navIsOpen: false}}>
          <Name>{name}</Name>
          <JobTitle>{jobTitle}</JobTitle>
        </Link>
        {email && <Email href={`mailto:${email}`}><span>Contact</span></Email>}
      </Section>
    </Container>
  )
}

Thumbnail.propTypes = {
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  url: PropTypes.string,
}


export default Thumbnail
